import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-rating',
  template: `
    <ng-container *ngFor="let r of ratings; let ix = index;">
      <svg (click)="rate(ix + 1)"
           [ngClass]="{'clickable': enabled}"
           style="margin-right: 0.3em;" width="66" height="66" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
        <g [attr.clip-path]="'url(#clip_' + ix + c +')'">
          <path
            d="M11.6142 1.36222L11.6142 1.36223L14.0955 6.38998C14.0955 6.38999 14.0955 6.39 14.0955 6.39001C14.3409 6.88736 14.8154 7.23192 15.3642 7.31153L20.9127 8.11781C21.4747 8.19953 21.6985 8.88969 21.2924 9.2855L21.2923 9.28553L17.2774 13.1991L17.2773 13.1992C16.8806 13.586 16.6991 14.1438 16.7931 14.6902C16.7931 14.6903 16.7931 14.6904 16.7931 14.6905L17.7407 20.2164L17.7407 20.2165C17.8367 20.7756 17.2496 21.2025 16.747 20.9384L11.7845 18.3296L11.7843 18.3295C11.2937 18.0718 10.7072 18.0718 10.2166 18.3295L10.2164 18.3296L5.25378 20.9385L5.25372 20.9385C4.7515 21.2026 4.16414 20.7759 4.26017 20.2165L4.26018 20.2164L5.20778 14.6905C5.30181 14.1439 5.12029 13.5861 4.72362 13.1992L4.72351 13.1991L0.708619 9.28561C0.301969 8.88905 0.526591 8.19921 1.08795 8.11784L1.08813 8.11781L6.63666 7.31153C7.18545 7.23192 7.65994 6.88735 7.90536 6.39001C7.90536 6.39 7.90537 6.38999 7.90537 6.38999L10.3867 1.36223L10.3868 1.36203C10.6375 0.853478 11.3629 0.853023 11.6142 1.36222Z"
            [attr.fill]="r !== 0 ? 'white': '#FFBD00'"
            [attr.stroke]="r !== 0 ? '#BDBDBD': '#FFBD00'"/>
        </g>
        <defs>
          <clipPath [attr.id]="'clip_' + ix + c">
            <rect width="22" height="22" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </ng-container>
  `
})
export class RatingComponent {
  @Output() rateChange = new EventEmitter<number>();
  @Input() enabled: boolean;

  private _rating: number;
  @Input()
  set rating(val) {
    this._rating = val;
    this.ratings = [1, 2, 3, 4, 5].map(r => this.rating >= r ? 0 : this.toFixed(this.rating - r));
  }
  get rating() {
    return this._rating;
  }
  ratings: number[] = [];
  c = Math.random(); // нужен для генерации уникальных ID

  constructor() {
  }

  rate(rating: number) {
    if (this.enabled) {
      if (this.rating === rating) {
        this.rating = 0;
      } else {
        this.rating = rating;
      }
    }
    this.rateChange.emit(this.rating);
  }

  private toFixed(val: number, fraction = 2): number {
    return Number(val.toFixed(fraction));
  }
}
