import { Component, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ComponentType, HxUserService, UserBasicModel } from 'hx-services';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { of, Subject } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

/**
 * Component search product info by search text
 */
@Component({
  selector: 'hx-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => HxUserSelectComponent),
    multi: true
  }]
})
export class HxUserSelectComponent implements OnInit, OnDestroy, ControlValueAccessor, OnChanges {

  @Input() multiple = false;
  @Input() includeDisabled = true;
  @Input() preload = false;
  @Input() storeId?: number;
  @Input() components: ComponentType[] = [];
  @Output() selectChange = new EventEmitter<UserBasicModel | UserBasicModel[] | undefined>();

  options: UserBasicModel[] = [];
  isLoading = false;
  selected: number | number[] | undefined;
  $searchChange = new Subject<string>();
  disabled = false;
  private $destroyed = new Subject<void>();

  constructor(
    private userService: HxUserService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('[user-select] changes', changes, this.preload);
    if (this.preload && changes['storeId'] && changes['storeId'].previousValue !== changes['storeId'].currentValue && changes['storeId'].currentValue) {
      this.isLoading = true;
      this.userService.getUsers({components: this.components, storeId: this.storeId, includeDisabled: this.includeDisabled})
        .subscribe(paged => {
          this.isLoading = false;
          this.options = paged.list;
        }, err => this.isLoading = false);
    }
  }

  ngOnDestroy(): void {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  ngOnInit(): void {
    this.$searchChange
      .pipe(
        takeUntil(this.$destroyed),
        filter(query => !!query && query.trim() !== ''),
        debounceTime(500),
        switchMap(query => {
          this.isLoading = true;
          if (this.preload) {
            const filtered = this.options.filter(op => op.fullname.toLowerCase().includes(query.toLowerCase()) || op.phone.includes(query.toLowerCase()));
            return of({list: filtered, count: filtered.length});
          }
          return this.userService.getUsers({
            query: query,
            components: this.components,
            storeId: this.storeId,
            includeDisabled: this.includeDisabled,
          });
        }),
        tap(() => this.isLoading = false),
        map(items => items.list),
        catchError(() => of([] as UserBasicModel[])),
      )
      .subscribe(users => {
        this.options = users;
      });
  }

  onModelChanged(val: any) {
    this.onChange(val);
    if (!this.multiple) {
      if (this.selected) {
        this.selectChange.emit(this.options.find(p => p.id === this.selected));
      } else {
        this.selectChange.emit();
      }
    } else {
      if (Array.isArray(this.selected)) {
        const ids: number[] = this.selected;
        this.selectChange.emit(this.options.filter(p => ids.includes(p.id)));
      } else {
        this.selectChange.emit([]);
      }
    }
  }

  onChange(val: any) {
    // console.log('onChange called', val);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj?: number | number[]): void {
    /*console.log('[HxUserSelect] writeValue called', obj);*/
    if (obj) {
      let ids: number[] = [];
      if (Array.isArray(obj)) {
        ids = ids.concat(obj);
      } else {
        ids.push(obj);
      }
      if (ids.length > 0) {
        this.userService.getUserByIds(ids).subscribe(users => this.options = users.list);
      }
    }
    this.selected = obj;
  }
}
