<a (click)="update()" class="is--update">
  <i class="la la-rotate-left la-2x"></i>
</a>

<div class="m-content">
  <div class="row" style="justify-content: center;">
    <div class="col-md-7">
      <h1>{{ 'main.rate' | transloco }}</h1>

      <form class="m-form m-form--state" *ngIf="store" [formGroup]="reviewForm" (ngSubmit)="onSubmitForm()">
        <div class="form-group m-form__group">
          <app-rating [rating]="rating" [enabled]="true" (rateChange)="onRatingChanged($event)"></app-rating>
        </div>

        <div class="form-group form-group--relative m-form__group" [ngClass]="{'has-danger': reviewForm.controls.phone.hasError('error')}">
          <input [patterns]="customPatterns" [showMaskTyped]="true" [prefix]="store.city.country.phonePrefix"
                 [mask]="store.city.country.phoneMask" type="tel" formControlName="phone"
                 (focus)="focusInput('tel')" (focusout)="focusOutInput('tel')"
                 [ngClass]="{'form-control-danger': reviewForm.controls.phone.hasError('error')}"
                 class="form-control form-control-lg">
          <small class="is--danger">{{ 'main.mobilNum' | transloco }}</small>
          <button *ngIf="show.tel" type="submit" class="btn btn-success btn-lg form-btn-right">
            {{ 'main.btn.send' | transloco }}
          </button>
        </div>

        <div class="form-group form-group--relative m-form__group">
          <textarea [placeholder]="'main.placeholder.feedback' | transloco" class="form-control form-control-lg" (focus)="focusInput('text')" (focusout)="focusOutInput('text')" [ngClass]="{'form-control-danger': reviewForm.controls.text.hasError('error')}" formControlName="text" rows="6"></textarea>

          <button *ngIf="show.text" type="submit" class="btn btn-success btn-lg form-btn-right">
            {{ 'main.btn.send' | transloco }}
          </button>
        </div>

        <div class="text-right m--margin-top-15 m--margin-bottom-15">
          <button type="submit" class="btn btn-success btn-lg">
            {{ 'main.btn.send' | transloco }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<div *ngIf="isLoading.submit" class="m-page-loader m-page-loader--non-block" style="margin-left: -80px; margin-top: -20px;">
  <div class="m-blockui">
    <span>
      {{ 'main.btn.sending' | transloco }}...
    </span>
    <span>
      <div class="m-loader m-loader--brand"></div>
    </span>
  </div>
</div>

<div *ngIf="isLoading.submit" class="k-modal__bg"></div>

<div class="ht-modal" *ngIf="isModalShow">
  <div class="ht-modal__body">
    <div *ngFor="let item of stores">
      <button type="button" class="btn btn-primary btn-block m--margin-bottom-15" (click)="setStore(item.id)">
        {{ item.title | uiLabel }}
      </button>
    </div>
  </div>
  <div class="ht-modal__bg"></div>
</div>
