// core
import { Component } from '@angular/core';
// plugins
import { TranslocoService } from '@ngneat/transloco';

// own

@Component({
  selector: 'app-home.m-grid.m-grid--hor.m-grid--root.m-page',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  constructor(
    private tr: TranslocoService,
  ) {
    tr.setDefaultLang('ru');
    tr.setActiveLang('ru');
  }
}
