import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';


/**
 * Own service for toster, implement ng2-toastr methods
 */
/**
 * @deprecated The method should not be used
 */
@Injectable({providedIn: 'root'})
export class HxToastrService {
  constructor(
    private toastr: ToastrService,
    private tr: TranslocoService
  ) { }

  public success(code: string, data?: any): void {
    this.toastr.success(this.tr.translate(code, data));
  }

  public error(code: string, data?: any): void {
    this.toastr.error(this.tr.translate(code, data));
  }

  public warning(code: string, data?: any): void {
    this.toastr.warning(this.tr.translate(code, data));
  }

  public info(code: string, data?: any): void {
    this.toastr.info(this.tr.translate(code, data));
  }
}
