//
import { Component, OnInit } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { HxAuthService } from 'hx-services';

@Component({
  selector: 'app-root', // eslint-disable-line
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  constructor(
    private authService: AuthService,
    public auth: HxAuthService,
  ) {
  }

  ngOnInit() {
    // 2 minuties
    const MS = 2 * 60 * 1000;

    const interval = setInterval(() => {
      if (this.checkTime()) {
        this.getUserToUpdateToken();
      } else {
        clearInterval(interval);
        this.logout();
      }
    }, MS);
  }

  private checkTime(): boolean {
    const hour = new Date().getHours();
    return hour < 20;
  }

  private logout() {
    this.authService.logout();
  }

  private getUserToUpdateToken() {
    this.auth.getIdentity().then(user => console.log('user', user));
  }
}
