import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ROUTES } from './app.routes';
import { AppComponent } from './app.component';

import { HomeComponent } from './home/home.component';
import { MainComponent } from './main/main.component';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';
import { HxComponentModule } from 'hx-component';
import { RatingComponent } from './rating/rating.component';
import { ComponentType, HX_APP_VERSION, HX_COMPONENT_NAME, HX_ENV_MODE, HX_KEYCLOAK, HxAuthService, HxPipeModule, initKeycloak, TranslocoRootModule } from 'hx-services';
import { environment } from '@kiosk-env/environment';
import { registerLocaleData } from '@angular/common';
import localeRuKZ from '@angular/common/locales/ru-KZ';
import { APP_VERSION } from '@kiosk-env/version';
import * as Sentry from '@sentry/browser';
import { TranslocoService } from '@ngneat/transloco';

if (environment.sentryUrl) {
  Sentry.init({
    release: environment.version,
    environment: environment.production ? 'production' : 'staging',
    ignoreErrors: ['Unauthorized', 'productBalance.zero'],
    dsn: environment.sentryUrl,
    beforeSend: (event, hint) => {
      console.log('[sentry] before send', event, hint);
      if (hint && hint.originalException && hint.originalException instanceof HttpErrorResponse) {
        if (hint.originalException.status === 401) {
          console.log('[sentry] skipped error logging', event);
          return null;
        }
      }
      return event;
    },
    integrations: [
      new Sentry.Integrations.TryCatch({
        XMLHttpRequest: false,
      }),
    ],
  });
}

registerLocaleData(localeRuKZ);

function getKeycloakUrl() {
  if (environment.production) {
    return location.origin.replace(location.origin.split('//')[1].split('.')[0], 'auth') + '/auth';
  }
  return environment.keycloakUrl;
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MainComponent,
    RatingComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(ROUTES),
    ToastrModule.forRoot(),
    TranslocoRootModule,
    KeycloakAngularModule,
    NgxMaskPipe,
    NgxMaskDirective,
    HxComponentModule,
    HxPipeModule,
  ],
  providers: [
    provideEnvironmentNgxMask(),
    {
      provide: LOCALE_ID,
      useValue: 'ru-KZ'
    },
    { provide: HX_COMPONENT_NAME, useValue: ComponentType.kiosk },
    { provide: HX_ENV_MODE, useValue: environment.production },
    {
      provide: HX_APP_VERSION,
      useValue: APP_VERSION
    },
    {
      provide: HX_KEYCLOAK,
      useValue: {
        url: getKeycloakUrl(),
        clientId: environment.keycloakClientId,
        realm: environment.keycloakRealm
      }
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initKeycloak,
      multi: true,
      deps: [KeycloakService, HxAuthService, HX_KEYCLOAK, TranslocoService],
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
