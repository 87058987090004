import { Injectable } from '@angular/core';
import { HxToastrService } from './toastr.service';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root'
})
export class HxErrorHandlerService {

  constructor(
    private toastr: HxToastrService,
    private tr: TranslocoService,
  ) { }

  /**
   * Error code:
   * 105 -
   * 106 - validation error
   * 111 - transformation error
   * 112 - balance error
   * 113 -
   * 115, 116 - limit error
   * 117 - balance booked error
   * @deprecated The method should not be used
   */
  check(error: {code: number, message: string, uiMessages?: any[], data?: any}, from?: string) {
    if (error && error.code) {
      switch (error.code) {
        case 105:
        case 113:
        case 115:
          this.toastr.warning(`${error.message}`);
          break;
        case 106:
          this.showToastr(error.uiMessages, from);
          break;
        case 112:
        case 117:
          if (error.data) {
            this.showToastrEmptyProduct(error.data?.order);
          }
          break;
        case 116:
          this.showToastrEmptyProduct(error.data?.order, 'limit');
          break;
        case 576:
          this.showToastr(error.data?.fields);
          break;
        default:
          this.toastr.error(error.message, error.data);
          break;
      }
    }
  }

  private showToastr(msg?: { field: string, message: string }[], from?: string) {
    msg = msg ?? [];
    for (let index = 0; index < msg.length; index++) {
      const element = msg[index];
      const prefix = from ? `${from}.` : '';
      const message = `${prefix}${element.field}.${element.message}`;
      this.toastr.error(message);
    }
  }

  private showToastrEmptyProduct(msg?: any, status?: string) {
    if (msg.product && msg.product.length) {
      let message = '';
      let list = '';
      msg.product.forEach((element: any, index: number) => {
        list += index > 0 ? `, ${element}` : `${element}`;
      });

      if (status && status === 'limit') {
        message = this.tr.translate('hx.error-handler.limit', {product: list});
      } else {
        message = this.tr.translate('hx.error-handler.balance', {msg: msg.store, product: list});
      }

      this.toastr.info(message);
    }
  }
}
