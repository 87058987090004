import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { HxErrorHandlerService, HxToastrService } from 'hx-component';
import { FeedbackModel, HxAuthService, HxFeedbackService, HxStoreService, StoreBasicModel, StoreFullModel } from 'hx-services';
import { TranslocoService } from '@ngneat/transloco';

/**
 * Main page for kiosk with form for client feedback
 */
@Component({
  selector: 'app-main.m-grid__item.m-grid__item--fluid.m-wrapper',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  reviewForm: UntypedFormGroup;
  isLoading = {
    submit: false
  };
  show = {
    tel: false,
    text: false
  };
  stores: StoreBasicModel[] = [];
  isModalShow = false;
  store: StoreFullModel;
  rating: number;

  customPatterns = { 'X': {
      pattern: new RegExp('/|0|4|5|6|7|/'),
    }, '9': {
      pattern: new RegExp('/|9|/'),
    }, 'N': {
      pattern: new RegExp('^(?!3)'),
    }, '0': {
      pattern: new RegExp('\\d'),
    }
  };

  constructor(
    private feedbackService: HxFeedbackService,
    public toaster: HxToastrService,
    public fb: UntypedFormBuilder,
    private storeService: HxStoreService,
    private auth: HxAuthService,
    private errorService: HxErrorHandlerService,
    private tr: TranslocoService
  ) {
  }

  ngOnInit() {
    const obj = localStorage.getItem('ht-kiosk-store');
    const store = JSON.parse(obj) as { id: number };

    this.reviewForm = this.fb.group({
      rating: [''],
      phone: [''],
      text: ['']
    });

    if (store && store.id) {
      this.setStore(store.id);
    } else {
      this.checkStores();
    }
  }

  setStore(storeId: number) {
    localStorage.setItem('ht-kiosk-store', JSON.stringify({id: storeId}));
    this.isModalShow = false;
    this.loadFullStore(storeId);
  }

  private loadFullStore(storeId: number) {
    this.storeService.getFullStore(storeId).then(res => {
      this.store = res;
    });
  }

  // public API
  onSubmitForm() {
    this.isLoading.submit = true;
    // prepare data
    const phone = this._rebuildPhone(this.reviewForm.value.phone);
    const text = this.reviewForm.value.text;
    const rating = this.reviewForm.value.rating;

    const kioskFeedback: FeedbackModel = {clientPhone: phone, clientText: text, reviews: [{rating: Number(rating)}]} as FeedbackModel;
    this.feedbackService.saveKioskFeedback(kioskFeedback).then(() => {
      this.isLoading.submit = false;
      this.resetForm();
      this.toaster.success(this.tr.translate('main.ts.feedback'));
    }, err => {
      this.errorService.check(err.error);
      this.isLoading.submit = false;
    });
  }

  private _rebuildPhone(phone: string) {
    return (this.store.city.country.phonePrefix + phone).replace(/\D+/g, '');
  }

  focusInput(val) {
    this.show[val] = true;
  }

  focusOutInput(val) {
    setTimeout(() => {
      this.show[val] = false;
    }, 500);
  }

  update() {
    window.location.reload();
  }

  onRatingChanged(rating: number) {
    this.rating = rating;
    this.reviewForm.patchValue({rating: rating});
  }

  private checkStores() {
    this.isModalShow = true;
    this.auth.getComponentStores().then(stores => {
      this.stores = stores.map(store => ({id: store.id, title: store.title} as StoreBasicModel));
    });
  }

  private resetForm() {
    this.rating = undefined;
    this.reviewForm.patchValue({
      rating: '',
      phone: '',
      text: ''
    });
  }
}
