import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private keycloakService: KeycloakService,
  ) { }

  logout() {
    this.keycloakService.logout();
    localStorage.removeItem('ht-kiosk-store');
  }

}
